import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  IconButton,
  Collapse,
  CircularProgress,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useSnackbar } from "../../../Contexts/SnackbarContext/SnackbarContext";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import FetchModule from "../../../Modules/FetchModule";
import { SelectChangeEvent } from "@mui/material";
import useFetch from '../../../Components/CommonUtility/useFetch';
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface RepackSeries {
  boxes: any;
  productId: string;
  isDeleted: boolean;
  amount: number;
  id: number;
  datesActive: {
    lowerBound: string;
    upperBound: string;
  };
  lastModified: string;
  modifiedBy: any;
  publicId: string;
  stagedBoxes: any;
}

const Repack: React.FC = () => {

  const { user } = useUser();
  const navigate = useNavigate();
  const { newSnackbarMessage } = useSnackbar();
  const [expanded, setExpanded] = useState<boolean>(true);
  const [stagedRepackSeries, setStagedRepackSeries] = useState<string>("");
  const [isNewSeries, setIsNewSeries] = useState<boolean>(false);
  const [newSeriesProductId, setNewSeriesProductId] = useState<string>("");
  const fetchModule = new FetchModule();
  const {data, loading} = useFetch("/repack/get/allstagedseries/", true, [])
  const handleSeriesChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    setStagedRepackSeries(value);
    setIsNewSeries(value === "new");
  };

  const handleButtonClick = async () => {
    if (isNewSeries) {
      try {
  
        const response = await fetchModule
          .postResponseBody("/repack/new/series", "POST", {
            UserId: user["id"],
            Body: {
              "productId": newSeriesProductId,
              "boxes": [],
              "modifiedBy": user,
              "isStaged": true,
            }
          })
          .then((res) => res.json());
  
        if (response && response.result) {
          const result = response.result;
          newSnackbarMessage("Repack series created successfully.", "success");
          navigate(`/dashboard/repack/create?id=${result.publicId}`);
        } else {
          throw new Error("Failed to create repack series");
        }
      } catch (error) {
        console.error("Error while creating repack series:", error);
        newSnackbarMessage("Error creating repack series", "error");
      } 
    } else {
      const repackSeries = data.find((series: any) => series.id === stagedRepackSeries);
      navigate(`/dashboard/repack/create?id=${repackSeries.publicId}`);
    }
  };  

  return (
    <div style={{ display: "flex", flexDirection: "column",  height: "100%"  }}>
      <div style={{ margin: 16 }}>
        <Paper style={{ padding: 16, position: "relative" }}>
          {loading && (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <CircularProgress />
            </div>
          )}
          <Typography variant="h5">Repacks</Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              marginTop: 16,
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body1" style={{ marginRight: 8 }}>
                {expanded
                  ? "Hide Create Repack Series"
                  : "Create Repack Series"}
              </Typography>
              <IconButton onClick={() => setExpanded(!expanded)}>
                {expanded ? <RemoveIcon /> : <AddIcon />}
              </IconButton>
            </div>
          </div>
          <Collapse in={expanded}>
            <Grid container spacing={2} style={{ marginTop: 16 }}>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id="select-repack-series-label">
                    Select Repack Series
                  </InputLabel>
                  <Select
                    labelId="select-repack-series-label"
                    id="select-repack-series"
                    label="Select Repack Series"
                    value={stagedRepackSeries}
                    onChange={handleSeriesChange}
                  >
                    {data.map((series: any) => (
                      <MenuItem key={series.id} value={series.id}>
                        {series.productId}
                      </MenuItem>
                    ))}
                    <MenuItem value="new">New</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {isNewSeries && (
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="New Series Product ID"
                    value={newSeriesProductId}
                    onChange={(e) => setNewSeriesProductId(e.target.value)}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                style={{ textAlign: "right", marginTop: isNewSeries ? 16 : 0 }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  disabled={
                    !stagedRepackSeries || (isNewSeries && !newSeriesProductId)
                  }
                  onClick={handleButtonClick}
                >
                  {isNewSeries
                    ? "Create Repack Series"
                    : "Modify Staged Repack Series"}
                </Button>
              </Grid>
            </Grid>
          </Collapse>
        </Paper>
      </div>

      <Paper
        style={{ padding: 16, margin: 16, flex: 1, overflowY: "scroll" }}
      ></Paper>
    </div>
  );
};

export default Repack;
