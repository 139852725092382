import React from "react";
import { TextField } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import propagateChanges from "../BulkCardsCreator/NumericPropogateChanges";

const handleKeyDown = (event) => {
  if (event.key === "Tab") {
    event.preventDefault();
    event.target.blur();
  }
};

function CustomNumberInput({ id, value, field }) {
  const apiRef = useGridApiContext();

  const handleValueChange = async (event) => {
    const newValue = event.target.value;
    const row = apiRef.current.getRow(id);
    const updatedTotalCost = calculateTotalCost(row, field, newValue);

    
    row[field] = Number(newValue);
    if (row.totalCost === row.comp || row.comp === 0) {
      await apiRef.current
        .setEditCellValue({
          id,
          field: "comp",
          value: Number(updatedTotalCost),
        });
  
      await apiRef.current.setEditCellValue({
        id,
        field: field,
        value: Number(newValue),
      });
    } else {
      await apiRef.current.setEditCellValue({
        id,
        field: field,
        value: Number(newValue),
      });
    }

    // Get the updated row and rows
    const updatedRow = apiRef.current.getRow(id);
    const rowsMap = apiRef.current.getRowModels();
    const allRows = Array.from(rowsMap.values());

    propagateChanges(updatedRow, row, allRows);

  };

  return (
    <TextField
      inputProps={{ style: { fontSize: 14 } }}
      size="small"
      fullWidth
      value={value}
      onChange={handleValueChange}
      onKeyDown={handleKeyDown}
      type="number"
    />
  );
}

function calculateTotalCost(row, changedField, newValue) {
  const tempRow = { ...row, [changedField]: Number(newValue) };
  return (tempRow.cost || 0) + (tempRow.fees || 0) + (tempRow.gradingFees || 0);
}

export default CustomNumberInput;
