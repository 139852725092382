import { GridFilterItem, GridFilterModel } from "@mui/x-data-grid";
import { GetDefaultFilter } from "./ServerSideDefaults";
import { useLocalStorage } from "../../../Utilities/LocalStorage/useLocalStorage";

export type FilterProps = {
  localStorageKey: string;
}

export type FilterContextProps = {
  filterModel: GridFilterModel,
  setFilterModel: React.Dispatch<React.SetStateAction<GridFilterModel>>,
  updateFilterModel: (field:string,operator:string,value:any) => void
  getPreprocessedFilterModel: () => GridFilterItem[]
}

export const useFilter = ({localStorageKey} : FilterProps) => {
  
  const [filterModel, setFilterModel] = useLocalStorage(localStorageKey,GetDefaultFilter());
  
  const updateFilterModel = (field:string,operator:string,value:any) => {
    setFilterModel((prev: GridFilterModel) => {
      if(prev.items.some((item: GridFilterItem) => item.field === field && item.operator === operator)){
        return {
          ...prev,
          items: prev.items.map((item: GridFilterItem) => {
            if(item.field === field && item.operator === operator){
              return {
                field,
                operator,
                value
              }
            }
            return item
          })
        }
      }
      else{
        return {
          ...prev,
          items: [
            ...prev.items,
            {
              field,
              operator,
              value
            }
          ]
        }
      }
    })
  }

  const getPreprocessedFilterModel = ()=>{
    const filteredItems = filterModel.items.filter((filterItem: GridFilterItem) => {
      if (
        filterItem.operator === "isEmpty" ||
        filterItem.operator === "isNotEmpty" ||
        filterItem.operator === "isGraded" ||
        filterItem.operator === "isRaw"
      ) {
        return true;
      }
    
      if (filterItem.field === 'inventoryId' && filterItem.value === "") {
        return false;
      }
      return !(filterItem.value === undefined || filterItem.value === null);
    });
    
    return {
      ...filterModel,
      items: filteredItems,
    };
  }
  return {
    filterModel,
    setFilterModel,
    updateFilterModel,
    getPreprocessedFilterModel
}
}




