import { useState } from "react";
import { GridPaginationModel } from "@mui/x-data-grid";
import { GetDefaultPagination } from "./ServerSideDefaults";
import { useLocalStorage } from "../../../Utilities/LocalStorage/useLocalStorage";


export type PaginationProps = {
  localStorageKey: string;

}

export type PaginationContextProps = {
  paginationModel: GridPaginationModel,
  setPaginationModel: React.Dispatch<React.SetStateAction<GridPaginationModel>>,
  resetPaginationModel: () => void,
  setPageSize: (pageSize: number) => void,
  setCurrentPage: (page: number) => void,
  lastEditWasManual: boolean,
  setLastEditWasManual: React.Dispatch<React.SetStateAction<boolean>>
}

export const usePagination = ({localStorageKey} : PaginationProps) => {
  
  const [paginationModel, setPaginationModel] = useLocalStorage(localStorageKey,GetDefaultPagination());
  const [lastEditWasManual, setLastEditWasManual] = useState(false);

  const resetPaginationModel = () => {
    setPaginationModel(GetDefaultPagination())
  }

  const setPageSize = (pageSize: number) => {
    setPaginationModel((prev: GridPaginationModel) => {
      return {
        ...prev,
        pageSize
      }
    })
  }
  const setCurrentPage = (page: number) => {
    setPaginationModel((prev: GridPaginationModel) => {
      return {
        ...prev,
        page
      }
    })
  }



  return {
      paginationModel,
      setPaginationModel,
      resetPaginationModel,
      setPageSize,
      setCurrentPage,
      lastEditWasManual,
      setLastEditWasManual
  }


}

