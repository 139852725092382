import { Box, Typography } from '@mui/material';
import { useSingleShow } from '../Contexts/ShowContexts/SingleShow/SingleShowContext';
import { FileUploaderProvider } from '../Components/FileUploader/FileUploaderContext';
import { useUser } from '../Components/CommonUtility/UserContext';
import { ShowSalesProvider } from '../Contexts/ShowSalesContexts/ShowSaleContext';
import { ShowSaleTypesProvider } from '../Contexts/ShowSalesContexts/ShowSaleTypesContext';
import ShowSaleTypeTabBar from '../Components/ShowSaleComponents/ShowSaleTypeTabBar';
import { ShowHostComissionsProvider } from '../Contexts/ShowContexts/ShowCommissions/ShowHostComissionsContext';
import { ShowProvider } from '../Contexts/ShowContexts/Shows/ShowContext';
import ShowCommissionHeader from '../Contexts/ShowContexts/ShowCommissions/ShowCommissionHeader';
import BigFileUploader from '../Components/FileUploader/BigFileUploader';
import { ShowSaleModifiedProvider } from '../Contexts/ShowSaleModifiedContexts/ShowSaleModifiedContext';

const SingleShowPage = ()  => {
    const {show, showError, showLoading, updateShow} = useSingleShow();
    const {user}: {user: any} = useUser();

    if (showLoading) return <Typography variant="h1">Loading...</Typography>;
    if (showError) return <Typography variant="h1">Error Loading</Typography>

    function renderFileUpload() {
        return (
            <>
            {renderHeader()}
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    paddingTop: '60px',
                    paddingBottom: '100px',
                    paddingLeft: '150px',
                    paddingRight: '150px'
                }}
            >
                <FileUploaderProvider endpoint={`/show/file/new/${show?.publicId}/${user.id}`} accepts='text/csv' maxFiles={5}>
                    <BigFileUploader supportsText="Supports: .csv" subText="Platforms: whatnot, fanatics, tiktok"/>
                </FileUploaderProvider>
            </Box>
            </>

        )
    }

    function renderComission() {
        return (
            <>
            <ShowSaleTypesProvider>
                <ShowSalesProvider>
                    <ShowProvider updateShow={updateShow}>
                    <ShowHostComissionsProvider>
                        <ShowSaleModifiedProvider>
                            {renderHeader()}
                            <ShowSaleTypeTabBar />
                        </ShowSaleModifiedProvider>
                    </ShowHostComissionsProvider>
                    </ShowProvider>
                </ShowSalesProvider>
            </ShowSaleTypesProvider>
            </>

        )
    }
    function renderHeader() {
        if (show?.showFile == null) {
            return <Typography variant="h5" sx={{marginBottom: '3px'}}>{show?.title || "Invalid show"}</Typography>
        } 
        return <ShowCommissionHeader />;
    }

    return(
    <Box
        sx={{
            width: '100%',
            height: '100%',
            padding: '5px',
            overflowY: 'scroll'
        }}
        >
            {show?.showFile == null ? renderFileUpload() : renderComission() }


    </Box>)
}
  
  export default SingleShowPage;
  