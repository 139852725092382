// RepackCreationContent.tsx
import React, { useCallback, useRef, useEffect, useState } from "react";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import DragAndDrop from "./DragToBox";
import SeriesInfoPanel from "./SeriesInfoPanel";
import TicketsTable from "./TicketView";
import RepackStorePreview from "./RepackStorePreview";
import { Typography } from "@mui/material";
import { RepackSeriesType } from "../Types/RepackSeriesType";
import FetchModule from "../../../Modules/FetchModule";
import { useSnackbar } from "../../../Contexts/SnackbarContext/SnackbarContext";
import StagedRepackPreview from "./StagedRepackPreview";

const fetchModule = new FetchModule();

export async function CreateBox(
  repackType: any,
  user: any,
  seriesId: any,
){
  const response = await fetchModule.postResponseBody("/Repack/new/box", "POST", 
  {
      UserId: user["id"],
      RepackType: repackType,
      SeriesId: seriesId,
      IsStaged: true,
  })
  return response    
}

export default function RepackCreationContent() {
  // hooks
  const { newSnackbarMessage } = useSnackbar();
  const useFetchGet = (url: string) => {
    const [data, setData] = useState<any[]>([]);
    useEffect(() => {
      fetchModule
        .fetchResponseAuthed(url, "GET")
        .then((res) => res.json())
        .then((data) => setData(data))
        .catch((error) => {
          console.error("Failed to fetch data:", error);
          newSnackbarMessage("Failed to fetch data.", "error");
        });
    }, [url]);
    return [data, setData] as [any[], React.Dispatch<React.SetStateAction<any[]>>];
  };
  const {
    types,
    boxes,
    setBoxes,
    tickets,
    selectedType,
    setSelectedType,
    selectedForRepack,
    addToRepackStoreRepackVersion,
    removeFromRepackStoreRepackVersion,
    id,
    setId,
  } = useBuildRepacks();

  //state + constants

  const dragAndDrop = useRef(
    new DragAndDrop(
      selectedForRepack,
      boxes,
      setBoxes,
      addToRepackStoreRepackVersion,
      removeFromRepackStoreRepackVersion
    )
  );

  const dragAndDropProducts = useRef(
    new DragAndDrop(
      tickets,
      boxes,
      setBoxes,
      addToRepackStoreRepackVersion,
      removeFromRepackStoreRepackVersion
    )
  )
const fetchStagedBoxes = useFetchGet(id ? `/repack/get/allstagedboxes/${id}` : "");

const [, setB] = useState<any[]>([]);

useEffect(() => {
  const fetchData = async () => {
    if (id && fetchStagedBoxes) {
      const response = await fetchStagedBoxes;
      setB(response);
    }
  };

  fetchData();
}, [id, fetchStagedBoxes]);  

  //use effects

  useEffect(() => {
    dragAndDrop.current.resetBoxes(boxes);
  }, [boxes]);

  useEffect(() => {
    dragAndDrop.current.resetItems(selectedForRepack);
  }, [selectedForRepack]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const idFromUrl = urlParams.get("id");
    setId(idFromUrl);

    if (idFromUrl) {
    }
  }, [setId]);

  

  //functional components

  const TicketView = useCallback(() => {
    return (
      <TicketsTable
        deleteProductFromStore={removeFromRepackStoreRepackVersion}
        drop={dragAndDropProducts.current.boxItemStopDrag}
        dragOver={dragAndDropProducts.current.handleDragOver}
        dragStart={dragAndDropProducts.current.cartItemDrag}
      />
    );
  }, [removeFromRepackStoreRepackVersion]);

  return (
    <>
      <Typography variant="h4" className="repack-creation-header">
        Repack Creation
      </Typography>
      <div className="repack-container">
        <div className="repack-store-preview">
          <div></div>
          <RepackStorePreview
            dragStart={dragAndDrop.current.cartItemDrag}
            dragOver={dragAndDrop.current.handleDragOver}
            drop={dragAndDrop.current.boxItemStopDrag}
          />
          <TicketView />
        </div>
        <div className="repacks">
          <StagedRepackPreview/>
          <SeriesInfoPanel
            types={types as RepackSeriesType[]}
            setSelectedType={setSelectedType}
            selectedType={selectedType}
            boxes={boxes}
          />
        </div>
      </div>
    </>
  );
}
