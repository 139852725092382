import React, { useEffect } from "react";
import {
  Button,
  TextField,
  Typography,
  Grid,
  Paper,
  IconButton,
  Collapse,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  DataGridPro,
  GridColDef,
  GridSortModel,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import { useRepackTicketContext } from "../../../Contexts/RepackContexts/RepackTicketContexts/RepackTicketContext";
import { RepackTicketType } from "../Types/RepackTicketType";

const RepackTicketManager: React.FC = () => {
  const { tickets, seriesList, ticketTypes } = useBuildRepacks();
  const {
    setCost,
    multipleTickets,
    numberOfTickets,
    ticketType,
    setTicketType,
    setTicketInputs,
    expanded,
    setExpanded,
    loading,
    prizes,
    setPrizes,
    prizeCosts,
    setPrizeCosts,
    series,
    setSeries,
    quantity,
    setQuantity,
    processRowUpdate,
    handleAddTickets,
  } = useRepackTicketContext();

  const mockPrizes = [
    { name: "Prize 1", cost: "$10" },
  ];

  const getUsername = (params: any) => {
    if (params.row.modifiedBy) {
      return params.row.modifiedBy.username;
    } else if (params.row.modifiedById) {
      const u = params.row.modifiedById;
      return u.username;
    }
  };

  const getTypeName = (params: any) => {
    const ticketType = ticketTypes.find(
      (type: any) => type.id === params.row.repackTicketTypeId
    );
    return ticketType ? ticketType.name : "Unknown";
  };

  const getSeriesName = (params: any) => {
    const series = seriesList.find(
      (s: any) => s.id === params.row.repackSeriesId
    );
    return series ? series.productId : "Unknown";
  };

  const computeAverageCost = () => {
    if (prizeCosts.length === 0) return 0;
    const totalCost = prizeCosts.reduce(
      (sum, cost) => sum + (parseFloat(cost) || 0),
      0
    );
    const temp = totalCost / prizeCosts.length;
    setCost(temp);
    return temp;
  };

  const averageCost = computeAverageCost();

  const columns: GridColDef[] = [
    { field: "id", headerName: "Id", width: 50, editable: false },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      editable: false,
      valueGetter: (params: any) => getTypeName(params),
    },
    {
      field: "cost",
      headerName: "Cost",
      type: "number",
      width: 150,
      editable: false,
    },
    {
      field: "repackItemId",
      headerName: "Repack Item Id",
      width: 150,
      editable: false,
    },
    {
      field: "modifiedBy",
      headerName: "Modified By",
      width: 150,
      valueGetter: (params: any) => getUsername(params),
      editable: false,
    },
    {
      field: "repackSeries",
      headerName: "Repack Series",
      width: 150,
      valueGetter: (params: any) => getSeriesName(params),
      editable: false,
    },
  ];

  const sortModel: GridSortModel = [
    {
      field: "id",
      sort: "asc",
    },
  ];

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = e.target.value;
    const parsedQuantity = parseInt(newQuantity, 10);
    if (!isNaN(parsedQuantity) && parsedQuantity > 0) {
      setPrizes(Array(parsedQuantity).fill(""));
      setPrizeCosts(Array(parsedQuantity).fill(""));
      setQuantity(parsedQuantity);
    } else {
      setPrizes([]);
      setPrizeCosts([]);
    }
  };

  const handlePrizeChange = (index: number, value: string) => {
    const updatedPrizes = [...prizes];
    updatedPrizes[index] = value;
    setPrizes(updatedPrizes);
  };

  const handlePrizeCostChange = (index: number, value: string) => {
    const updatedCosts = [...prizeCosts];
    updatedCosts[index] = value;
    setPrizeCosts(updatedCosts);
  };

  useEffect(() => {
    if (multipleTickets && numberOfTickets) {
      const count = parseInt(numberOfTickets as string, 10);
      const inputs = Array.from({ length: count }).map(() => ({
        name: "",
        cost: "",
      }));
      setTicketInputs(inputs);
    }
  }, [multipleTickets, numberOfTickets, setTicketInputs]);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "90%", margin: 16 }}>
      <Paper
        style={{
          padding: 16,
          marginBottom: 16,
          position: "relative",
          maxHeight: expanded ? "400px" : "100px",
          overflowY: "auto",
          flexShrink: 0,
        }}
      >
        {/* Expand/Collapse Button */}
        <IconButton
          style={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1,
          }}
          onClick={() => setExpanded(!expanded)}
        >
          {expanded ? <RemoveIcon /> : <AddIcon />}
        </IconButton>

        <Typography
          variant="h5"
          gutterBottom
          style={{
            marginBottom: 16,
            opacity: expanded ? 1 : 0.7,
            transition: "opacity 0.3s ease",
          }}
        >
          Add Tickets
        </Typography>

        <Collapse in={expanded}>
          <Grid container spacing={2}>
            {/* First Row: Ticket Type, Cost, Quantity */}
            <Grid item xs={12} md={4}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Ticket Type</InputLabel>
                <Select
                  value={ticketType}
                  onChange={(e) => setTicketType(e.target.value as RepackTicketType)}
                  label="Ticket Type"
                >
                  {ticketTypes && ticketTypes.length > 0 ? (
                    ticketTypes.map((type: any) => (
                      <MenuItem key={type.id} value={type}>
                        {type.name}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No Ticket Types Available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Ticket Cost"
                type="number"
                value={averageCost}
                disabled
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Quantity"
                type="number"
                value={quantity}
                onChange={handleQuantityChange}
                variant="outlined"
              />
            </Grid>
            {/* Dynamic Prize Inputs */}
            {prizes.map((prize, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={`Prize ${index + 1}`}
                    value={prize}
                    onChange={(e) => handlePrizeChange(index, e.target.value)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={`Prize Cost ${index + 1}`}
                    type="number"
                    value={prizeCosts[index] || ""}
                    onChange={(e) =>
                      handlePrizeCostChange(index, e.target.value)
                    }
                    variant="outlined"
                  />
                </Grid>
              </React.Fragment>
            ))}
            {/* Third Row: Series */}
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Series</InputLabel>
                <Select
                  label="Series"
                  value={series}
                  onChange={(e) => setSeries(e.target.value)}
                >
                  {seriesList && seriesList.length > 0 ? (
                    seriesList.map((seriesItem: any) => (
                      <MenuItem key={seriesItem.id} value={seriesItem.id}>
                        {seriesItem.productId}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No Series Available</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddTickets}
                disabled={loading}
                startIcon={loading ? <CircularProgress size={24} /> : null}
              >
                {loading ? "Adding..." : "Add Tickets"}
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </Paper>

      <div style={{ flex: 1, display: "flex", flexDirection: "column", marginTop: 16, overflowY: "scroll" }}>
        <Paper style={{ flex: 1 }}>
          <DataGridPro
            rows={tickets}
            columns={columns}
            processRowUpdate={processRowUpdate}
            sortModel={sortModel}
            components={{
              Toolbar: GridToolbarContainer,
            }}
            componentsProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
            getDetailPanelContent={() => (
              <div style={{ padding: 16 }}>
                <Typography variant="h6">Prizes</Typography>
                <List>
                  {mockPrizes.map((prize, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={prize.name} secondary={`Cost: ${prize.cost}`} />
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
            getDetailPanelHeight={() => 200}
          />
        </Paper>
      </div>
    </div>
  );
};

export default RepackTicketManager;