import React, { Component } from 'react';
import SportsTabs from './SportTabs';
import './wholesale.css';
import logo from '../images/WTH_HORIZONTAL.png';

export default class WholesalePage extends Component {
    constructor(props) {
        super(props) 
        this.state = {
            redirect: "",
            left: 0,
            font: 10

        }
        window.addEventListener('resize', this.handleResize);
        if(window.innerWidth > 1000) {
            this.state.left = (window.innerWidth - 1000) /2 
            this.state.font = 15;
        }
        else if(window.innerWidth < 400) {
            this.setState({font: 10})
        } else {
            this.setState({font: 13})
        }
    }
    handleResize = () => {
        if (window.innerWidth > 1000) {
            let give = (window.innerWidth - 1000) /2 
            this.setState({left: give})
            this.setState({font: 15})
        } else {
            if(window.innerWidth < 400) {
                this.setState({font: 10})
            } else {
                this.setState({font: 13})
            }
            this.setState({left: 0})
        }
    }
    render() {
        return (
            <div className="homepage">
                <div className="navbar homepage-nav">
                <div 
                    className="leftText"
                    href="/" 
                    style={{
                        marginLeft: this.state.left,
                    }}
                    >

                    <img src={logo}
                    alt="WeTheHobby"
                    style={{
                        width:120,
                        marginTop:-5,
                    }}
                    />
                </div>
                <div 
                className="rightText"
                style={{color: 'white',
                    marginRight: this.state.left,
                    fontSize: this.state.font
            }}>145 College Ave <div>Rochester, NY 14607</div></div>
            </div>
                <div className="homepage-body">
                    <SportsTabs link={this.props.link}/>
                </div>
            </div>
            
        )

    }


}