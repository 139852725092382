import { Box, Typography } from '@mui/material';
import { useShowSales } from '../../Contexts/ShowSalesContexts/ShowSaleContext';

const ShowSaleDataGridFooter = ()  => {
    const {getSelectedSales, sales, selectedBreaks} = useShowSales();
    return (
      <Box sx={{ p: 1, display: 'flex' }}>
        <Typography variant="body2" sx={{px: 2}}>{getSelectedSales().length} selected sales</Typography>
        <Typography variant="body2" sx={{px: 2}}>{Object.keys(selectedBreaks).length} selected breaks</Typography>
        <Typography variant="body2">Total Sales: {sales.length}</Typography>
      </Box>
    );
}

  export default ShowSaleDataGridFooter;
