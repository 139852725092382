import FilterList from "../FilterComponents/FilterList";
import BulkChangeStatus from "./BulkChangeStatus";
import "../../Hamburger.css";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridFilterModel,
} from "@mui/x-data-grid";
import { Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import "../singles.css";
import { Card } from "../Types/CardType";
import CardExport from "../CardExport";

interface CardsToolbarProps {
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  snackbar: any;
  setSnackbar: React.Dispatch<React.SetStateAction<any>>;
  onFilterChange: (filter: any) => void;
  premadeFilter: GridFilterModel;
  selectedRows: Card[];
  colOrder: string[];
  colVis: any;
  changeStatusOnLiveCells: any;
  filterModel: any;
  setFilterModel: any;
}

const CardsToolbar: React.FC<CardsToolbarProps> = ({
  
  refresh,
  setRefresh,
  snackbar,
  setSnackbar,
  selectedRows,
  colOrder,
  colVis,
  changeStatusOnLiveCells,
  filterModel, setFilterModel
}) => {

  const handleClearFilters = () => {
    setFilterModel({
      items: [],
      quickFilterValues: [],
    });
    setRefresh(!refresh);
  };

  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      <GridToolbarFilterButton />
      <Button
        startIcon={<DeleteIcon />}
        onClick={handleClearFilters}
      >
        Clear Filters
      </Button>
      <FilterList
        refresh={refresh}
        setRefresh={setRefresh}
        snackbar={snackbar}
        setSnackbar={setSnackbar}
        setFilterModel={setFilterModel} 
        filterModel={filterModel}
        deleteUrl="/Card/delete/filter"
        saveUrl="/Card/save/filter"      
      />
      <BulkChangeStatus 
        selectedRows={selectedRows} 
        changeStatusOnLiveCells={changeStatusOnLiveCells}
      />
      <CardExport filterModel={filterModel} colOrder={colOrder} colVis={colVis} />
    </GridToolbarContainer>
  );
};

export default CardsToolbar;
