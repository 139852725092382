import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  DataGridPro,
  GridColDef,
  GridRowId,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import Button from "@mui/material/Button";
import { useSnackbar } from "../../../Contexts/SnackbarContext/SnackbarContext";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import FetchModule from "../../../Modules/FetchModule";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import { RepackBox } from "../Types/RepackBox";
import { RepackBoxType } from "../Types/RepackBoxType";

const fetchModule = new FetchModule();

const StagedRepackPreview = () => {
  const [searchParams] = useSearchParams();
  const [boxes, setBoxes] = useState<any[]>([]);
  const [itemsData, setItemsData] = useState<any>({});
  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);
  const seriesId = searchParams.get("id");
  const { newSnackbarMessage } = useSnackbar();
  const { user } = useUser();
  const { types, selectedType, setSelectedBox } = useBuildRepacks();

  const fetchBoxes = async () => {
    try {
      const response = await fetchModule.fetchResponseAuthed(
        `/Repack/get/allstagedboxes/${seriesId}`,
        "GET"
      );
      const data = await response.json();
      const modifiedBoxes = modifyBoxesWithRepackType(data);
      setBoxes(modifiedBoxes);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      newSnackbarMessage("Failed to fetch boxes.", "error");
    }
  };

  const fetchItemsForBox = async (boxId: GridRowId) => {
    try {
      const response = await fetchModule.fetchResponseAuthed(
        `/Repack/get/items/${boxId}`,
        "GET"
      );
      const data = await response.json();
      setItemsData((prev: any) => ({ ...prev, [boxId]: data }));
    } catch (error) {
      console.error("Failed to fetch items:", error);
      newSnackbarMessage(`Failed to fetch items for box ${boxId}.`, "error");
    }
  };

  useEffect(() => {
    fetchBoxes();
  }, [seriesId]);

  const handleCreateBox = async () => {
    if (!selectedType) {
      newSnackbarMessage("Please select a repack type.", "error");
      return;
    }

    try {
      const response = await fetchModule.postResponseBody(
        "/Repack/new/box",
        "POST",
        {
          Body: {
            UserId: user["id"],
            RepackType: selectedType,
            SeriesId: seriesId,
            IsStaged: true,
          },
        }
      );

      if (response.ok) {
        newSnackbarMessage("Box created successfully!", "success");
        fetchBoxes();
      } else {
        newSnackbarMessage("Failed to create box.", "error");
      }
    } catch (error) {
      console.error("Failed to create box:", error);
      newSnackbarMessage("Error creating box.", "error");
    }
  };

  const mapRepackTypeName = (box: RepackBox) => {
    const type = types.find((type: RepackBoxType) => type.id === box.repackBoxTypeId);
    return type ? type.name : "Unknown Type";
  };

  const modifyBoxesWithRepackType = (boxesData: any[]) => {
    return boxesData.map((box: any) => ({
      ...box,
      repackType: mapRepackTypeName(box),
    }));
  };

  const handleRowClick = (params: any) => {
    const selectedBox = params.row;
    setSelectedBox(selectedBox);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 150 },
    { field: "repackType", headerName: "Repack Type", width: 200 },
    { field: "seriesId", headerName: "Series ID", width: 150 },
    { field: "isStaged", headerName: "Is Staged", width: 150 },
  ];

  const itemColumns: GridColDef[] = [
    { field: "itemId", headerName: "Item ID", width: 150 },
    { field: "ticket", headerName: "Ticket", width: 200 },
  ];

  const getDetailPanelContent = (params: any) => {
    const boxId = params.row.id;
    const items = itemsData[boxId] || [];
    return (
      <div style={{ padding: "10px" }}>
        <div style={{ height: 200, width: "100%" }}>
          <DataGridPro rows={items} columns={itemColumns} />
        </div>
      </div>
    );
  };

  const getDetailPanelHeight = (params: any) => {
    return itemsData[params.row.id] ? 300 : 100;
  };

  const handleRowExpandChange = (ids: GridRowId[]) => {
    const newlyExpandedBoxId = ids.find((id) => !expandedRowIds.includes(id));
    if (newlyExpandedBoxId) {
      fetchItemsForBox(newlyExpandedBoxId);
    }
    setExpandedRowIds(ids);
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Button variant="contained" onClick={handleCreateBox}>
        Add New Box
      </Button>
    </GridToolbarContainer>
  );

  return (
    <div style={{ height: 600, width: "100%" }}>
      <DataGridPro
        rows={boxes}
        columns={columns}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        detailPanelExpandedRowIds={expandedRowIds}
        onDetailPanelExpandedRowIdsChange={handleRowExpandChange}
        onRowClick={handleRowClick}
        components={{ Toolbar: CustomToolbar }}
        sx={{
          boxShadow: 2,
          border: 2,
          borderColor: "primary.light",
          "& .MuiDataGrid-cell:hover": {
            color: "primary.main",
          },
        }}
      />
    </div>
  );
};

export default StagedRepackPreview;