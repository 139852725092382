import React, { useEffect, useState } from "react";
import { FormControl, TextField } from "@material-ui/core";
import useDebounce from "../../../../Components/CommonUtility/useDebounce";
import "./Filter.css"; 
interface BasicFilterInputProps {
  field: string;
  operator: string;
  filterModel: any, updateFilterModel: any
}

const BasicFilterInput: React.FC<BasicFilterInputProps> = ({
  field,
  operator,
  filterModel, updateFilterModel
}) => {


  const extractInitialValueFromFilter = ( field:string,operator:string) => {
    const filter = filterModel.items.find((item: { field: string; operator: string; }) => item.field === field && item.operator === operator);
    return filter?.value || [];
  };
  const [selectedValue, setSelectedValue] = useState<string>(extractInitialValueFromFilter(field,operator));
  const debouncedValue = useDebounce(selectedValue, 250);

  useEffect(() => {
    if(debouncedValue !== undefined && debouncedValue !== null){
      updateFilterModel(field, operator, debouncedValue);
    }
  }, [debouncedValue]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value || "");
  };

  return (
    <div>
      <FormControl>
        <TextField
          margin="dense"
          id={field + "-" + operator + "-" + "TextField"}
          label={field}
          type="text"
          variant="standard"
          onChange={handleInputChange}
          value={selectedValue}
          className="filter"
        />
      </FormControl>
    </div>
  );
};

export default BasicFilterInput;
