import React from "react";
import "../../Hamburger.css";
import { Label } from "reactstrap";
import {
  useGridApiContext,
} from "@mui/x-data-grid";
import Pagination from "@mui/material/Pagination";
import { Button, Select, MenuItem, InputLabel } from "@mui/material";
import FormControl from "@material-ui/core/FormControl";
import {Card} from "../Types/CardType"

interface CardPaginationProps {
  rowCount: number;
  getTotalPrice: () => number;
  getTotalComp: () => number;
  
  setJumpPage: (page: (page: number) => void) => void;
  selectedForRepack: Card[];
  paginationModel: any,
  setPageSize: any,
  setCurrentPage: (page: number) => void,
  totalRows: number,
  goTo: any
}

function CardPagination(props: CardPaginationProps) {
  const apiRef = useGridApiContext();
  const {
    getTotalPrice,
    getTotalComp,
    setJumpPage,
    selectedForRepack,
    paginationModel,setPageSize,setCurrentPage,
    totalRows,goTo
  } = props;

  function handlePageJump(page: number) {
    setCurrentPage(page)
    apiRef.current.setPage(page);
  }

  setJumpPage(handlePageJump);

  const pageCount = Math.ceil(totalRows / paginationModel.pageSize);

  return (
    <div className="pagination-box" style={{ maxHeight: '100px', overflowY: 'hidden'}}>
      <div className="row-count">
        Total Rows: {totalRows}
        <Label
          style={{
            paddingLeft: ".5rem",
            paddingTop: ".5rem",
            paddingRight: ".5rem",
            fontSize: "14px",
          }}
        >
          Amount Selected: {getTotalPrice()}
        </Label>
        <Label
          style={{
            paddingTop: ".5rem",
            paddingRight: ".5rem",
            fontSize: "14px",
          }}
        >
          Comp Selected: {getTotalComp()}
        </Label>
        <Label
          style={{
            paddingTop: ".5rem",
            paddingRight: ".5rem",
            fontSize: "14px",
          }}
        >
          Cards Selected For Repack: {selectedForRepack.length}
        </Label>
      </div>
      <Button className="btn" onClick={()=> goTo("graded")}>
        Jump to Last Graded Card
      </Button>
      <Button className="btn" onClick={()=>goTo("raw")}>
        Jump to Last Raw Card
      </Button>
      <Button className="btn" onClick={()=>goTo("pending")}>
        Jump to Last Pending Card
      </Button>
      <Button className="btn" onClick={()=>goTo("retail")}>
        Jump to Last Retail Card
      </Button>
      <div className="form-control-box">
        <FormControl className="form-control">
          <InputLabel style={{ fontSize: "12px" }} shrink>
            Page Size
          </InputLabel>
          <Select
            value={paginationModel.pageSize ?? 150}
            onChange={(event) => setPageSize(event.target.value as number)} // Ensure event value is cast to number
            style={{ fontSize: "12px", paddingLeft: ".25rem", maxWidth: "80px", maxHeight: "30px"}}
          >
            <MenuItem value={150}>150</MenuItem>
            <MenuItem value={500}>500</MenuItem>
            <MenuItem value={750}>750</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Pagination
        showFirstButton
        showLastButton
        color="primary"
        count={pageCount}
        page={paginationModel.page}
        siblingCount={0}
        onChange={(event, value) => {
          setCurrentPage(value);
          apiRef.current.setPage(value);
        }}
      />
    </div>
  );
}

export default CardPagination;
