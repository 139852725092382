import React from 'react';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-premium';

const ShowSaleCommissionDataGridToolbar: React.FC = ({}) => {

    return (
        <GridToolbarContainer>
            <GridToolbarExport/>
        </GridToolbarContainer>
    );
};

export default ShowSaleCommissionDataGridToolbar;