export enum LocalStorageKey {
  CARD_DASHBOARD_WIDTH = "cardColumnWidths",
  CARD_DASHBOARD_ORDER = "cardColOrder",
  CARD_DASHBOARD_VISIBILITY = "columnFilterOptions",
  CARD_DASHBOARD_FILTERS = "cardFilterOptions",  
  CARD_DASHBOARD_SORT = "cardSortOptions",
  CARD_DASHBOARD_PAGINATION = "cardPaginationOptions",
  CARD_BULKSTATUS_WIDTH = "cardColumnWidthsModal",
  CARD_REPACK_CURRENTBOXES = "currentBoxes",   
  CARD_REPACK_SELECTEDFORREPACK = "selectedForRepack",
  CARD_BULK_SHOWUPDATEMESSAGE = "showUpdateMessage",      
}