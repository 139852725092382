import React, { useState } from "react";
import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { GridRowId } from "@mui/x-data-grid";
import CartViewToolbar from "./CartViewToolbar";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import "./Repack.css";

interface TicketsTableProps {
  deleteProductFromStore: (arg0: GridRowId) => void;
  drop: (event: React.DragEvent<HTMLDivElement>) => void;
  dragOver: (event: React.DragEvent<HTMLDivElement>) => void;
  dragStart: (event: React.DragEvent<HTMLDivElement>) => void;
}

export default function TicketsTable(props: TicketsTableProps) {

  const {
    setSelectedTickets,
    tickets,
  } = useBuildRepacks();
  const columns = [
    { field: "id", headerName: "Id", editable: false },
    { field: "name", headerName: "Product", editable: false },
    { field: "cost", headerName: "Cost", editable: false },
  ];

  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleToggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  const handleRowSelectionChange = (ids: GridRowId[]) => {
    const selectedIDs = new Set(ids);
    const selectedRows = tickets.filter((row: any) =>
      selectedIDs.has(row.id)
    );
    setSelectionModel(ids);
    setSelectedTickets(selectedRows);
  };

  return (
    <div
      onDrop={props.drop}
      onDragOver={props.dragOver}
      className="tickets-view"
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={handleToggleCollapse}>
          {isCollapsed ? <AddIcon /> : <RemoveIcon />}
        </IconButton>
        <span>Add Tickets</span>
      </Box>
      {!isCollapsed && (
        <Box sx={{ height: "100%", width: "100%" }}>
          <DataGridPro
            sx={{
              boxShadow: 2,
              border: 2,
              borderColor: "primary.light",
              "& .MuiDataGrid-cell:hover": {
                color: "primary.main",
              },
            }}
            slots={{
              toolbar: CartViewToolbar,
            }}
            slotProps={{
              toolbar: {displayType: "tickets"},
              row: {
                draggable: "true",
                onDragStart: (event) => props.dragStart(event),
                onDragOver: (event) => props.dragOver(event),
              },
            }}
            density="compact"
            rows={tickets}
            columns={columns}
            pagination={false}
            disableColumnReorder={true}
            checkboxSelection
            onRowSelectionModelChange={handleRowSelectionChange}
            rowSelectionModel={selectionModel}
          />
        </Box>
      )}
    </div>
  );
}