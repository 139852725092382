import { Box, Button, Dialog, Divider, Typography } from '@mui/material';

import { useSingleShow } from '../SingleShow/SingleShowContext';
import { DownloadFileGet } from '../../../Utilities/DownloadFile';
import ModalOpenerButton from '../../../Components/Common/ModalOpenerButton';
import { useShow } from '../Shows/ShowContext';
import { useState } from 'react';
import SimpleFileUploader from '../../../Components/FileUploader/SimpleFileUploader';
import { FileUploaderProvider } from '../../../Components/FileUploader/FileUploaderContext';
import { useUser } from '../../../Components/CommonUtility/UserContext';


type ShowCommissionHeaderProps = {
    children?: React.ReactNode;
}
const ShowCommissionHeader = ({} : ShowCommissionHeaderProps)  => {
    const {show} = useSingleShow();
    const {setEditFields} = useShow();
    const {user}: {user: any} = useUser();
    const [open, setOpen] = useState(false);

    async function downloadFile() {
        DownloadFileGet(`/show/file/${show?.showFile.publicId}`, show?.showFile.fileName)
    }
    
    return <>
            <Box sx={{
            width: '100%',
            height: '40px',
            display: 'flex',
            flexDirection: 'row',
            }}>
                <Typography variant="h5" sx={{marginBottom: '3px'}}>{show?.title || "Invalid show"}</Typography>
                <Button onClick={() => downloadFile()}>Download Raw File</Button>
                <ModalOpenerButton text="Edit Show" setOpen={() => show && setEditFields(show)} icon='edit' />
                <ModalOpenerButton text="Reupload File" setOpen={() => setOpen(true)} icon='edit' />
            </Box>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <FileUploaderProvider endpoint={`/show/file/update/${show?.publicId}/${user.id}`} accepts='text/csv' maxFiles={1}>
                    <SimpleFileUploader uploadHandledExternally={false}
                    />
                </FileUploaderProvider>
            </Dialog>
            <Divider sx={{borderBottomWidth: 3}}/>
        </>

}
  
  export default ShowCommissionHeader;
  