import { RepackBox } from "../Types/RepackBox";
import React from "react";

export default class DragAndDrop {
  items: any[];
  boxes: RepackBox[];
  setBoxes: (boxes: RepackBox[]) => void;
  setItems: (items: any) => void;
  deleteItem:any

  constructor(items: any[], boxes: RepackBox[], setBoxes: (boxes: RepackBox[]) => void, setItems: (items: any[]) => void, deleteItem:any) {
    
    this.items = items;
    this.boxes = boxes;
    this.setBoxes = setBoxes;
    this.setItems = setItems;
    this.deleteItem = deleteItem

    // Binding here if necessary
    this.cartItemDrag = this.cartItemDrag.bind(this);
    this.handleDropOntoBox = this.handleDropOntoBox.bind(this);
    this.handleDropBoxContents = this.handleDropBoxContents.bind(this);
    this.boxItemStartDrag = this.boxItemStartDrag.bind(this);
    this.boxItemStopDrag = this.boxItemStopDrag.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);

    this.resetBoxes = this.resetBoxes.bind(this);
    this.resetItems = this.resetItems.bind(this);

  }


  resetItems(items: any[]): void {
    this.items = items;
  }
  resetBoxes(boxes: RepackBox[]): void {
    this.boxes = boxes;
  }
  

  getById(list: any[], id: number): any {
    return list.find((item) => Math.abs(item.id - id) < 0.000001) || null;
  }

  addToBoxes(idToUpdate: number, itemToAdd: any, boxesCopy: RepackBox[]): RepackBox[] {
    const boxIndex = boxesCopy.findIndex((box) => box.id === idToUpdate);
    if (boxIndex !== -1) {
      const newItems = [...boxesCopy[boxIndex].items, itemToAdd];
      boxesCopy[boxIndex].items = newItems;
      return [
          ...boxesCopy.slice(0, boxIndex),
          boxesCopy[boxIndex],
          ...boxesCopy.slice(boxIndex + 1),
      ];
    }

    return boxesCopy;
  }

  removeFromBox(boxId: number, cardId: number): void {
    const boxIndex = this.boxes.findIndex((box) => box.id === boxId);
    if (boxIndex !== -1) {
      const newItems = this.boxes[boxIndex].items.filter((item: any) => item.id !== cardId);
      this.boxes[boxIndex].items = newItems;
      this.setBoxes([
          ...this.boxes.slice(0, boxIndex),
          this.boxes[boxIndex],
          ...this.boxes.slice(boxIndex + 1),
      ]);
    } else {
      console.error("There was a problem removing the card from the box");
    }
  }

  //handle the start of a card drag
  cartItemDrag(event: React.DragEvent<HTMLDivElement>): void {
    const target = event.target as HTMLElement;
    const element = target.closest('[data-id]');
    if (element !== null) {
      const id = Number(element.getAttribute('data-id'));
      const item = this.getById(this.items, id);
      if (item) {
        const payload = JSON.stringify({ payload: item, signature: { Origin: "cart", id } });
        event.dataTransfer.setData("application/json", payload);
      }
    }
  }

  //handle the dropping of a card in a box
  handleDropOntoBox(event: React.DragEvent<HTMLDivElement>): void {
    event.preventDefault();
    const temp = event.dataTransfer.getData("application/json")
    const payload = JSON.parse(temp);

    const target = event.target as HTMLElement;
    const element = target.closest('[data-id]');

    if(element !== null){
      if (payload.signature.Origin === "cart") {
        const derivedBoxRow: any = {...this.getById(this.boxes, Number(element.getAttribute('data-id')))};
        if (derivedBoxRow) {
          const items: any[] = [...derivedBoxRow.items];
          items.push(payload.payload);
          const newBoxes = this.boxes.map((box)=>{
            if(box.id === derivedBoxRow.id){
              const i =  {
                id: derivedBoxRow.id, 
                items:items, 
                productId: derivedBoxRow.productId,
                cardId: derivedBoxRow.cardId,
                boxId: derivedBoxRow.boxId,
                isStaged: derivedBoxRow.isStaged,
                tickets: derivedBoxRow.tickets,
                cards: derivedBoxRow.cards,
                attributes
                : 
                []
              }
              return i
            }
            else{
              return box
            }
          });
          this.setBoxes(newBoxes);

          this.deleteItem(payload.payload.id);
        }
      }
    }
    
  }

  //handle the dropping of a card in a boxes contentents that originated in the cart
  handleDropBoxContents(event: React.DragEvent<HTMLDivElement>, row: any): void {
    const payload = JSON.parse(event.dataTransfer.getData("application/json"));
    if (payload.signature.Origin === "cart") {
      this.setBoxes(this.addToBoxes(row.id, payload.payload, [...this.boxes]));
      this.deleteItem(payload.payload.id);
    }
  }


  //handle the start of a drag of an item out of a box
  boxItemStartDrag(event: React.DragEvent<HTMLDivElement>, startBox:{ row: RepackBox}): void {
    const target = event.target as HTMLElement;
    const element = target.closest('[data-id]');
    if(element !== null){
      const id = Number(element.getAttribute('data-id'));
      const item = this.getById(startBox.row.items, id);
      if (item) {
        const payload = JSON.stringify({ payload: item, signature: { Origin: "box", id: startBox.row.id } });
        event.dataTransfer.setData("application/json", payload);
      }
    }
    
  }


  //Handle the dropping of a card in the cart that originated in a box
  boxItemStopDrag(event: React.DragEvent<HTMLDivElement>): void {
    try{
      const payload = JSON.parse(event.dataTransfer.getData("application/json"));
      if (payload.signature.Origin === "box") {
        this.setItems([...this.items,payload.payload]);
        this.removeFromBox(payload.signature.id, payload.payload.id);
      }
    }
    catch{
      console.error("Error Error Error")
    }
    
  }

  handleDragOver(event: React.DragEvent<HTMLDivElement>): void {
    event.preventDefault();
  }
}
