import { Box } from '@mui/material';
import Repack from '../Dash/RepackPage/RepackPage/RepackPage';

const RepackPage = () => (
    <Box
        sx={{
            width: '100%',
            height: '100%',
            padding: '5px'
        }}
        >
        <Repack/>

    </Box>
  );
  
  
  export default RepackPage;