import React, { createContext, useContext, useState, useEffect, useCallback } from "react";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import { useSnackbar } from "../../SnackbarContext/SnackbarContext";
import { useLocalStorage } from "../../../Utilities/LocalStorage/useLocalStorage";
import FetchModule from "../../../Modules/FetchModule";
import { RepackSeriesType } from "../../../Dash/RepackPage/Types/RepackSeriesType";
import { RepackBox } from "../../../Dash/RepackPage/Types/RepackBox";
import { RepackTicket } from "../../../Dash/RepackPage/Types/RepackTicket";
import useFetch from "../../../Components/CommonUtility/useFetch";
import { LocalStorageKey } from "../../../Utilities/LocalStorage/LocalStorageEnum";

const fetchModule = new FetchModule();
const BuildRepacksContext = createContext<BuildRepacksContextProps | null>(null);

interface HandleAddTicketParams {
  ticketName: string;
  setTicketName: React.Dispatch<React.SetStateAction<string>>;
  cost: string | number;
  setCost: React.Dispatch<React.SetStateAction<string | number>>;
  multipleTickets: boolean;
  setMultipleTickets: React.Dispatch<React.SetStateAction<boolean>>;
  numberOfTickets: string | number;
  setNumberOfTickets: React.Dispatch<React.SetStateAction<string | number>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  repackSeriesId: number;
}

interface BuildRepacksContextProps {
  types: any[];
  setTypes: React.Dispatch<React.SetStateAction<any[]>>;
  boxes: any[];
  setBoxes: React.Dispatch<React.SetStateAction<any[]>>;
  selectedBox: any;
  setSelectedBox: React.Dispatch<React.SetStateAction<any>>;
  selectedRegularItems: any[];
  setSelectedRegularItems: React.Dispatch<React.SetStateAction<any[]>>;
  selectedTickets: any[];
  setSelectedTickets: React.Dispatch<React.SetStateAction<any[]>>;
  selectedType: RepackSeriesType | string;
  setSelectedType: React.Dispatch<React.SetStateAction<RepackSeriesType | string>>;
  addToNewBox: () => void;
  addToSelectedBox: () => void;
  addToNewBoxTicket: () => void;
  addToSelectedBoxTicket: () => void;
  selectedForRepack: any[];
  setSelectedForRepack: React.Dispatch<React.SetStateAction<any[]>>;
  addToRepackStoreRepackVersion: (rows: any) => void;
  removeFromRepackStoreRepackVersion: (id: any) => void;
  addToRepackStore: (rows: any) => void;
  clearRepackStore: () => void;
  removeFromRepackStore: (id: any) => void;
  removeItemFromRepack: (itemId: any, boxId: any) => void;
  addToTickets: (product: any) => void; 
  tickets: RepackTicket[];
  setTickets: (ticket: any) => void;
  handleAddTicket: (params: HandleAddTicketParams) => void;
  id: string | null;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  ticketTypes: any[];
  setTicketTypes: React.Dispatch<React.SetStateAction<any[]>>;
  seriesList: any[];
  setSeriesList: React.Dispatch<React.SetStateAction<any[]>>;
}

type BuildRepacksProviderProps = {
  children?: React.ReactNode;
};

export const BuildRepacksProvider = ({ children }: BuildRepacksProviderProps) => {

  // CONTEXT
  const { newSnackbarMessage } = useSnackbar();
  const { user } = useUser();

  // STATE
  const { data: types, setData: setTypes } = useFetch("/Repack/get/types", true, []);
  const { data: tickets, setData: setTickets } = useFetch("/Repack/get/alltickets", true, []);
  const { data: ticketTypes, setData: setTicketTypes } = useFetch("/Repack/get/tickettypes", true, []);
  const { data: seriesList, setData: setSeriesList } = useFetch("/Repack/get/allseries", true, []);

  
  useEffect(() => {
    const fetchData = async () => {
      const ticketsResponse = await fetchModule.fetchResponseAuthed("/Repack/get/alltickets", "GET");
      const ticketsResponseData = await ticketsResponse.json();
      setTickets(ticketsResponseData);

      const ticketTypesResponse = await fetchModule.fetchResponseAuthed("/Repack/get/tickettypes", "GET");
      const ticketTypesResponseData = await ticketTypesResponse.json();
      setTicketTypes(ticketTypesResponseData);
    };

    fetchData();
  }, [setTicketTypes, setTickets]); 

  const [id, setId] = useState<string | null>(null);
  const [boxes, setBoxes] = useLocalStorage(LocalStorageKey.CARD_REPACK_CURRENTBOXES, []);
  const [selectedBox, setSelectedBox] = useState<any>();
  const [selectedRegularItems, setSelectedRegularItems] = useState<any[]>([]);
  const [selectedTickets, setSelectedTickets] = useState<any[]>([]);
  const [selectedType, setSelectedType] = useState<RepackSeriesType | string>("");
  const [selectedForRepack, setSelectedForRepack] = useLocalStorage(
    LocalStorageKey.CARD_REPACK_SELECTEDFORREPACK,
    []
  );


  const handleAddTicket = async ({
    ticketName,
    setTicketName,
    cost,
    setCost,
    multipleTickets,
    numberOfTickets,
    setNumberOfTickets,
    repackSeriesId,
    setLoading
  }: HandleAddTicketParams) => {
    if (!ticketName || cost === "" || isNaN(Number(cost))) {
      newSnackbarMessage("Please provide valid ticket details.", "error");
      return;
    }
  
    const numberOfItems = multipleTickets ? Number(numberOfTickets) : 1;
  
    if (
      multipleTickets &&
      (numberOfTickets === "" ||
        isNaN(Number(numberOfTickets)) ||
        Number(numberOfTickets) <= 1)
    ) {
      newSnackbarMessage(
        "Please provide a valid number of tickets greater than 1.",
        "error"
      );
      return;
    }
  
    setLoading(true);
  
    try {
      const newTickets: RepackTicket[] = [];
      const response = await fetchModule.postResponseBody(
        "/repack/new/ticket",
        "POST",
        {
          UserId: user["id"],
          Name: ticketName,
          Cost: Number(cost),
          NumberOfTickets: numberOfItems,
          RepackSeriesId: repackSeriesId
        }
      );
  
      const data = await response.json();
  
      for (let i = 0; i < numberOfItems; i++) {
        const newTicket: RepackTicket = {
          id: Date.now() + i,
          cost: Number(cost),
        };
        newTickets.push(newTicket);
      }
  
      setTickets([...tickets, ...data]);
      setTicketName("");
      setCost("");
      setNumberOfTickets("");
      newSnackbarMessage("Ticket(s) added successfully.", "success");
    } catch (error) {
      console.error("Failed to add ticket(s):", error);
      newSnackbarMessage("Failed to add ticket(s).", "error");
    } finally {
      setLoading(false);
    }
  };
  

  const removeItemFromRepack = useCallback((itemId: number, boxId: number) => {
    setBoxes((prevRows: any) => {
      const updatedBoxes = prevRows.map((row: any) => {
        if (row.id === boxId) {
          return {
            ...row,
            items: row.items.filter((item: any) => item.id !== itemId),
          };
        }
        return row;
      });
      return updatedBoxes;
    });

    // Check if the item is a ticket
    setSelectedTickets((prevTickets) => {
      const item = prevTickets.find((p) => p.id === itemId);
      if (item) {
        setSelectedTickets((prev) => [...prev, item]);
      }
      return prevTickets.filter((p) => p.id !== itemId);
    });

    // Add item to selectedForRepack
    setSelectedForRepack((prevSelectedForRepack: any) => {
      const item = selectedRegularItems.find((s) => s.id === itemId);
      if (item) {
        return [...prevSelectedForRepack, item];
      }
      return prevSelectedForRepack;
    });
  }, [selectedRegularItems, setBoxes, setSelectedForRepack]);

  const addToRepackStoreRepackVersion = (rows: any) => {
    const tempStore = [...selectedForRepack];
    rows.forEach((card: any) => {
      if (!tempStore.some((e) => e.id === card.id)) {
        tempStore.push(card);
      }
    });
    setSelectedForRepack(tempStore);
  };

  const removeFromRepackStoreRepackVersion = (rows: any) => {
    const updatedStore = selectedForRepack.filter(
      (e: any) => !rows.some((card: any) => e.id === card.id)
    );
  
    setSelectedForRepack(updatedStore);
  };

  const addToRepackStore = (rows: any) => {
    const tempStore = [...selectedForRepack];
    rows.forEach((card: any) => {
      if (!tempStore.some((e) => e.id === card.id)) {
        tempStore.push(card);
      }
    });
    setSelectedForRepack(tempStore);
  };

  const clearRepackStore = () => {
    setSelectedForRepack([]);
  };

  const removeFromRepackStore = (id: any) => {
    const filtererdList = selectedForRepack.filter((e: any) => e.id !== id);
    setSelectedForRepack(filtererdList);
  };

  const addToNewBox = () => {
    if (!selectedRegularItems || selectedRegularItems.length === 0) {
      newSnackbarMessage(
        "There are no cards selected to add to a new repack box",
        "error"
      );
      console.error("No valid selected cards.");
      return;
    }

    const boxToAdd = { id: boxes.length, items: [...selectedRegularItems] };
    const boxesCopy = [...boxes];
    boxesCopy.push(boxToAdd);
    setBoxes(boxesCopy);

    const updatedSelectedForRepack = selectedForRepack.filter(
      (item: any) => !selectedRegularItems.some((selectedItem: any) => selectedItem.id === item.id)
    );
    setSelectedForRepack(updatedSelectedForRepack);
  };

  const addToSelectedBox = () => {
    let converter: { id: number; items: any[] } | undefined;

    if (typeof selectedBox === "number") {
      converter = boxes.find((box: RepackBox) => box.id === selectedBox);
    } else if (Array.isArray(selectedBox) && selectedBox.length > 0) {
      converter = JSON.parse(JSON.stringify(selectedBox[0]));
    }

    if (!converter) {
      newSnackbarMessage(
        'No valid selected box found. Make sure you\'ve selected the row, not just the "+" icon',
        "error"
      );
      console.error("No valid selected box found.");
      return;
    }

    const tempBox = { ...converter, items: [...converter.items] };

    selectedRegularItems.forEach((card: any) => {
      tempBox.items.push(card);
    });

    const updatedObjects = boxes.map((obj: RepackBox) =>
      obj.id === tempBox.id ? tempBox : obj
    );
    setBoxes(updatedObjects);
    setSelectedBox(tempBox);

    const updatedSelectedForRepack = selectedForRepack.filter(
      (item: any) => !selectedRegularItems.some((selectedItem: any) => selectedItem.id === item.id)
    );
    setSelectedForRepack(updatedSelectedForRepack);
  };

  const addToNewBoxTicket = () => {
    if (!selectedTickets || selectedTickets.length === 0) {
      newSnackbarMessage(
        "There are no tickets selected to add to a new repack box",
        "error"
      );
      console.error("No valid selected tickets.");
      return;
    }

    const boxToAdd = { id: boxes.length, items: [...selectedTickets] };
    const boxesCopy = [...boxes];
    boxesCopy.push(boxToAdd);
    setBoxes(boxesCopy);

    const updatedTickets = tickets.filter(
      // eslint-disable-next-line
      (item: any) => !selectedTickets.some((selectedItem: any) => selectedItem.id === selectedItem.id)
    );
    setTickets(updatedTickets);
  };

  const addToSelectedBoxTicket = () => {
    let converter: { id: number; items: any[] } | undefined;

    if (typeof selectedBox === "number") {
      converter = boxes.find((box: RepackBox) => box.id === selectedBox);
    } else if (Array.isArray(selectedBox) && selectedBox.length > 0) {
      converter = JSON.parse(JSON.stringify(selectedBox[0]));
    }

    if (!converter) {
      newSnackbarMessage(
        'No valid selected box found. Make sure you\'ve selected the row, not just the "+" icon',
        "error"
      );
      console.error("No valid selected box found.");
      return;
    }

    const tempBox = { ...converter, items: [...converter.items] };

    selectedTickets.forEach((product: any) => {
      tempBox.items.push(product);
    });

    const updatedObjects = boxes.map((obj: RepackBox) =>
      obj.id === tempBox.id ? tempBox : obj
    );
    setBoxes(updatedObjects);
    setSelectedBox(tempBox);

    const updatedTickets = tickets.filter(
      // eslint-disable-next-line
      (item: any) => !selectedTickets.some((selectedItem: any) => selectedItem.id === selectedItem.id)
    );
    setTickets(updatedTickets);
  };

  const addToTickets = (product: any) => {
    setTickets((prevTickets: any[]) => {
      if (prevTickets.some((p) => p.id === product.id)) {
        newSnackbarMessage("Product already exists in the list.", "error");
        return prevTickets;
      }
      return [...prevTickets, product];
    });
  };  

  const contextValues = { 
    types, setTypes,
    boxes, setBoxes,
    selectedBox, setSelectedBox,
    selectedRegularItems, setSelectedRegularItems,
    selectedTickets, setSelectedTickets,
    selectedType, setSelectedType,
    handleAddTicket,
    addToNewBox,
    addToSelectedBox,
    addToNewBoxTicket,
    addToSelectedBoxTicket,
    selectedForRepack,
    setSelectedForRepack,
    tickets, setTickets,
    addToRepackStoreRepackVersion,
    clearRepackStore,
    removeFromRepackStoreRepackVersion,
    addToRepackStore,
    removeFromRepackStore,
    removeItemFromRepack,
    addToTickets,
    id,
    setId,
    ticketTypes,
    setTicketTypes,
    seriesList,
    setSeriesList,
  };

  return (
    <BuildRepacksContext.Provider value={contextValues}>
      {children}
    </BuildRepacksContext.Provider>
  );
};

export function useBuildRepacks() {
  const context = useContext(BuildRepacksContext);
  if (!context) {
    throw new Error(
      "Invalid BuildRepacksContext, must be wrapped in a BuildRepacksProvider"
    );
  }
  return context;
}