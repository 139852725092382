
import { useState } from "react";
import { DataGridAutocompleteProps } from "./DatagridAutocompleteTypes";
import DataGridAutocompleteComponent from "./DatagridAutocompleteComponent";

export const DataGridAutocomplete = ({id,field,initValue,data,width,handleAddToData,typePublicId,labelPath,isMultiselect,propogateOnBlur,row,isGrouped}:DataGridAutocompleteProps)=>{
  const [value,setValue] = useState(initValue);
  
  return (
    <DataGridAutocompleteComponent
      id={id}
      field={field}
      value={value}
      data={data} 
      setValue={setValue}
      width={width}
      handleAddToData={handleAddToData}
      typePublicId={typePublicId}
      labelPath={labelPath}    
      isMultiselect={isMultiselect}
      propogateOnBlur={propogateOnBlur}
      isGrouped={isGrouped}
      row={row}
      />
  )
}